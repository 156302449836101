var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import ApplicationController from "@/controllers/application_controller";
import Rails from "@rails/ujs";
import { LinkModule } from "@/modules/link_module";
import { ResultsStoreModule } from "@/modules/results_store_module";
import { EventTrackerModule } from "@/modules/event_tracker_module";
import ahoy from "ahoy.js";
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";
const linkModuleInst = new LinkModule();
function setCookie(cName, cValue, expDays) {
  let date = new Date();
  date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1e3);
  const expires = "expires=" + date.toUTCString();
  document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
}
export default class component_controller_default extends ApplicationController {
  constructor() {
    super(...arguments);
    __publicField(this, "linkModule", linkModuleInst);
    __publicField(this, "trackerModule", new EventTrackerModule(ahoy));
  }
  initialize() {
    console.log("[FORM BUILDER] initialize");
    console.log("this.submitRedirectUrlValue", this.submitRedirectUrlValue);
    console.log("this.submitPopupUrlValue", this.submitPopupUrlValue);
    this.parentQuestionController;
    this.getQuestionController();
    const extractFieldNameFromParamsWrapper = (fieldName) => {
      const startOfWrapper = fieldName == null ? void 0 : fieldName.lastIndexOf("[");
      const endOfWrapper = fieldName == null ? void 0 : fieldName.lastIndexOf("]");
      return fieldName == null ? void 0 : fieldName.slice(startOfWrapper + 1, endOfWrapper);
    };
    const fillInputsFromQueryParams = () => {
      const urlParams = new URLSearchParams(window.location.search);
      if (this.hasFieldTarget) {
        this.fieldTargets.map((field) => {
          const fieldName = extractFieldNameFromParamsWrapper(field == null ? void 0 : field.getAttribute("name"));
          const fieldData = field.dataset;
          const fieldParam = fieldData.param ? fieldData.param : fieldName;
          const paramValue = urlParams.get(fieldParam);
          if (paramValue)
            field.value = paramValue;
        });
      }
    };
    fillInputsFromQueryParams();
  }
  connect() {
    console.log("[FORM BUILDER] connect");
    this.trackRender();
  }
  trackRender() {
    this.trackerModule.track("lead_form_render", {
      lead_form_id: this.idValue,
      submit_redirect_url: this.submitRedirectUrlValue,
      submit_popup_url: this.submitPopupUrlValue,
      page: window.location.pathname,
      url: window.location.href
    });
  }
  getFormJSON(form) {
    const data = new FormData(form);
    return Array.from(data.keys()).reduce((result, key) => {
      result[key] = data.get(key);
      return result;
    }, {});
  }
  trackSubmit(form) {
    return __async(this, null, function* () {
      const saveToQuestionsStore = (key, text) => __async(this, null, function* () {
        this.resultsStoreModule = yield ResultsStoreModule();
        yield this.resultsStoreModule.init();
        yield this.resultsStoreModule.saveAnswer(key, text);
      });
      const saveToUserStore = (key, text) => __async(this, null, function* () {
        this.userStoreModule = yield ResultsStoreModule("savedUser");
        yield this.userStoreModule.init();
        yield this.userStoreModule.saveAnswer(key, text);
      });
      const formData = this.getFormJSON(form);
      const customFieldsToStore = [
        "birth",
        "zip",
        "address",
        "dob",
        "date_of_birth",
        "datetime",
        "optin_dt",
        "social"
      ];
      customFieldsToStore.map((field) => {
        const val = formData[`lead[custom_fields[${field}]]`];
        if (val)
          saveToQuestionsStore(field, val);
      });
      const leadFieldsToStore = ["email", "first_name", "last_name", "phone"];
      leadFieldsToStore.map((field) => {
        const val = formData[`lead[${field}]`];
        if (val)
          saveToUserStore(field, val);
      });
      this.trackerModule.track("lead_form_submit", {
        form_data: formData,
        submit_redirect_url: this.submitRedirectUrlValue,
        submit_popup_url: this.submitPopupUrlValue,
        page: window.location.pathname,
        url: window.location.href,
        lead_form_id: this.idValue
      });
    });
  }
  trackSubmitSuccess() {
    this.trackerModule.track("lead_form_submit_success", {
      lead_form_id: this.idValue,
      submit_redirect_url: this.submitRedirectUrlValue,
      submit_popup_url: this.submitPopupUrlValue,
      page: window.location.pathname,
      url: window.location.href
    });
  }
  trackSubmitError(errors) {
    this.trackerModule.track("lead_form_submit_error", {
      errors,
      lead_form_id: this.idValue,
      submit_redirect_url: this.submitRedirectUrlValue,
      submit_popup_url: this.submitPopupUrlValue,
      page: window.location.pathname,
      url: window.location.href
    });
  }
  submitForm(event) {
    this.clearErrors();
    event.preventDefault();
    if (this.validatePresence()) {
      this.trackSubmit(this.formTarget);
      if (this.checkSkyflow())
        this.enableSw();
      Rails.fire(this.formTarget, "submit");
    }
  }
  onSubmitSuccess(event) {
    var _a;
    console.log("success", event);
    this.trackSubmitSuccess();
    let [data, status, xhr] = event.detail;
    console.log("xhr?.response", xhr == null ? void 0 : xhr.response);
    const lead = (_a = JSON.parse(xhr == null ? void 0 : xhr.response)) == null ? void 0 : _a.lead;
    console.log("leadid", lead == null ? void 0 : lead.id);
    setCookie("lead_id", lead == null ? void 0 : lead.id);
    const popupUrl = this.submitPopupUrlValue;
    const redirectUrl = this.submitRedirectUrlValue;
    const isFormDataParamsPassingTurnedOff = () => {
      console.log("this.element", this.element);
      console.log("this.element.hasAttribute", this.element.hasAttribute("data-turn-off-form-data-params-passing"));
      return this.element.hasAttribute("data-turn-off-form-data-params-passing") && this.element.getAttribute("data-turn-off-form-data-params-passing") == "nopass";
    };
    const getFormUrlParams = (object) => {
      const formUrlParams = {};
      const formUrlWhitelist = isFormDataParamsPassingTurnedOff() ? [] : ["first_name", "last_name", "email", "phone"];
      console.log("form whitelist", formUrlWhitelist);
      Object.keys(object).map((key) => {
        if (formUrlWhitelist.includes(key) && object[key])
          formUrlParams[key] = lead[key];
      });
      return new URLSearchParams(formUrlParams).toString();
    };
    this.switchForm();
    if (popupUrl && popupUrl.length > 0) {
      this.linkModule.performRedirect(this.linkModule.addCurrentParamsToUrl(this.linkModule.addParamsToUrl(popupUrl)(getFormUrlParams(lead))), true);
    }
    if (redirectUrl && redirectUrl.length > 0) {
      this.linkModule.performRedirect(this.linkModule.addCurrentParamsToUrl(this.linkModule.addParamsToUrl(redirectUrl)(getFormUrlParams(lead))));
    }
  }
  onSubmitError(event) {
    var _a;
    let [data, status, xhr] = event.detail;
    const rawErrors = xhr == null ? void 0 : xhr.response;
    const errors = rawErrors && rawErrors.lenght > 0 && ((_a = JSON.parse(rawErrors)) == null ? void 0 : _a.errors);
    this.trackSubmitError(errors);
    const fields = this.formTarget.querySelectorAll("input, textarea, select");
    Object.entries(errors).map(([key, val]) => {
      const invalidFields = Array.from(fields).filter((field) => {
        return [`lead[${key}]`, `lead[custom_fields[${key}]]`].includes(field.getAttribute("name"));
      });
      console.log("invalidFields", invalidFields);
      const errClasses = `border border-error`.split(" ");
      invalidFields.forEach((f) => {
        f.classList.add(...errClasses);
        const errBlock = f.parentNode.querySelector(`span#error_lead_${key}`);
        errBlock.innerHTML = val;
      });
    });
  }
  switchForm() {
    const inactiveClasses = ["hidden"];
    console.log("switchForm");
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.classList.add("disabled:opacity-50", "pointer-events-none");
      if (this.hasSubmitLoaderTarget)
        this.submitLoaderTarget.classList.remove("hidden");
    }
    if (this.hasChainTarget) {
      this.formTarget.classList.add(...inactiveClasses);
      this.chainTarget.classList.remove(...inactiveClasses);
    }
    if (this.parentQuestionController) {
      console.log("THE LEAD FORM IS INSIDE QUESTION", this.parentQuestionController);
      this.parentQuestionController.handleAnswer();
    }
  }
  validatePresence() {
    let isValid = true;
    let requiredFieldSelectors = "select:required, textarea:required, input:required";
    let requiredFields = this.formTarget.querySelectorAll(requiredFieldSelectors);
    const errClasses = `border border-error`.split(" ");
    requiredFields.forEach((field) => {
      if (!field.disabled && !field.value.trim()) {
        field.classList.add(...errClasses);
        const errBlock = field.parentNode.querySelector(`span[id^='error']`);
        if (errBlock)
          errBlock.innerHTML = "This field is required.";
        const toolTip = tippy(field, {
          interactive: true,
          animation: "fade",
          content: "Please Fill Out This Field",
          trigger: "click"
        });
        toolTip == null ? void 0 : toolTip.show();
        field == null ? void 0 : field.focus();
        isValid = false;
      }
    });
    return isValid;
  }
  clearErrors() {
    this.errorMessageTarget.innerHTML = "";
    const fields = this.formTarget.querySelectorAll("input, textarea, select");
    Array.from(fields).forEach((f) => {
      f.classList.remove("border-error");
      const errBlock = f.parentNode.querySelector(`span[id^='error']`);
      if (errBlock)
        f.parentNode.querySelector(`span[id^='error']`).innerHTML = "";
    });
  }
  checkSkyflow() {
    const SKYFLOW_ATTR_ENABLED_STATUS = "enable";
    const isSkyflowEnabledOnForm = this.element.hasAttribute("data-enable-skyflow") && this.element.getAttribute("data-enable-skyflow") === SKYFLOW_ATTR_ENABLED_STATUS;
    return isSkyflowEnabledOnForm;
  }
  enableSw() {
    var _a;
    if (this.formTarget && ((_a = this.formTarget) == null ? void 0 : _a.action))
      this.formTarget.action = this.linkModule.addParamsToUrl(this.formTarget.action)(`sw=true&no_t=true&creds_id=${this.sfCredsValue}`);
  }
  getQuestionController() {
    const parentQuestion = this.element.closest('[data-controller="chain--answer--lead-form--component chain--answer--component"]');
    if (parentQuestion) {
      this.parentQuestionController = this.leadformQuestionController(parentQuestion);
    }
  }
  leadformQuestionController(el) {
    return this.application.getControllerForElementAndIdentifier(el, "chain--answer--lead-form--component");
  }
}
__publicField(component_controller_default, "targets", [
  "errorMessage",
  "form",
  "chain",
  "field",
  "submitButton",
  "submitLoader"
]);
__publicField(component_controller_default, "values", {
  id: String,
  sfCreds: String,
  submitRedirectUrl: String,
  submitPopupUrl: String
});

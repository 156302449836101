var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import ApplicationController from "@/controllers/application_controller";
export default class component_controller_default extends ApplicationController {
  initialize() {
    console.log("Answer::CheckboxComponent::Controller[index]", this.element);
  }
  connect() {
    console.log("Answer::CheckboxComponent::Controller[connect]");
  }
  handleSubmit(event) {
    return __async(this, null, function* () {
      console.log("CHECKBOX SUBMIT", this.questionTargets);
      const promiseChain = (funcs) => funcs.reduce((promise, func) => promise.then((result) => func().then(Array.prototype.concat.bind(result))), Promise.resolve([]));
      const checkboxFuncs = this.questionTargets.map((answer) => () => this.checkboxQuestionController(answer).handleAnswer());
      yield promiseChain(checkboxFuncs);
      this.answerController.getAnswerData(this.checkboxSubmitTarget);
      this.answerController.handleAnswer();
    });
  }
  checkboxQuestionController(el) {
    return this.application.getControllerForElementAndIdentifier(el, "chain--answer--checkbox--question--component");
  }
  get answerController() {
    return this.application.getControllerForElementAndIdentifier(this.element, "chain--answer--component");
  }
}
__publicField(component_controller_default, "targets", ["question", "checkboxSubmit"]);

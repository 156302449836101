var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import ApplicationController from "@/controllers/application_controller";
import { EventTrackerModule } from "@/modules/event_tracker_module";
import ahoy from "ahoy.js";
export default class component_controller_default extends ApplicationController {
  constructor() {
    super(...arguments);
    __publicField(this, "trackerModule", new EventTrackerModule(ahoy));
  }
  initialize() {
  }
  connect() {
  }
  log() {
  }
  handleRowClick(e) {
    return __async(this, null, function* () {
      this.trackRender();
    });
  }
  trackRender() {
    const userData = JSON.parse(localStorage.getItem("savedUser") || "{}");
    const questionResults = JSON.parse(localStorage.getItem("questions_results") || "{}");
    this.trackerModule.track("trialscope_listing_click", {
      site_id: this.trialscopeSiteIdValue,
      policy_id: this.trialscopePolicyIdValue,
      text: this.trialscopeTextValue,
      title: this.trialscopeTitleValue,
      user_data: userData,
      questions_results: questionResults
    });
  }
}
__publicField(component_controller_default, "values", {
  trialscopeSiteId: String,
  trialscopePolicyId: String,
  trialscopeText: String,
  trialscopeTitle: String
});

var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import ApplicationController from "@/controllers/application_controller";
import { EventTrackerModule } from "@/modules/event_tracker_module";
import ahoy from "ahoy.js";
export default class component_controller_default extends ApplicationController {
  constructor() {
    super(...arguments);
    __publicField(this, "trackerModule", new EventTrackerModule(ahoy));
  }
  initialize() {
    this.activeValue = false;
    console.log("[Chain::Question::ComponentController::initialize]", this.element);
  }
  connect() {
    console.log("[Chain::Question::ComponentController::connect]", this.element);
    this.activeValue = true;
    this.initProgressbar();
    this.trackRender();
  }
  disconnect() {
    this.activeValue = false;
    console.log("[Chain::Question::ComponentController::disconnect]", this.questionIdValue);
  }
  trackRender() {
    console.log("trackRender()", this.questionIdValue);
    this.trackerModule.track("question_render", {
      "question_id": this.questionIdValue,
      "question_text": this.questionTextValue,
      "question_chain_id": this.chainIdValue,
      "page": window.location.pathname,
      "url": window.location.href
    });
  }
  activeValueChanged() {
    console.log("activeValue", this.activeValue);
    const activeClasses = ["transform-none", "opacity-100"];
    const inactiveClasses = ["transform", "translate-y-3", "opacity-0"];
    this.element.classList.remove("hidden");
    if (this.activeValue) {
      this.element.classList.remove(...inactiveClasses);
      this.element.classList.add(...activeClasses);
    } else {
      this.element.classList.remove(...activeClasses);
      this.element.classList.add(...inactiveClasses);
    }
  }
  initProgressbar() {
    if (this.progressbarController)
      this.progressbarController.hideProgressBar(this.hideProgressbarValue);
  }
  get progressbarController() {
    return this.application.getControllerForElementAndIdentifier(document.querySelector(".progress-bar"), "chain--progress--component");
  }
}
__publicField(component_controller_default, "values", { questionId: String, questionText: String, chainId: String, hideProgressbar: Boolean, active: Boolean });

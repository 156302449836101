var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import ApplicationController from "@/controllers/application_controller";
export default class component_controller_default extends ApplicationController {
  initialize() {
    console.log("Answer::OpenEnded::ComponentController[index]", this.element);
  }
  connect() {
    console.log("Answer::OpenEnded::ComponentController[connect]", this.element);
  }
  handleOpenEndedInput(event) {
    console.log("Answer::OpenEnded::ComponentController::handleOpenEndedInput]", event);
    const input = event.target;
    this.openEndedSubmitTarget.dataset.text = input.value;
  }
  handleSubmit(event) {
    event.preventDefault();
    this.answerController.getAnswerData(event.target);
    this.answerController.handleAnswer();
  }
  get answerController() {
    return this.application.getControllerForElementAndIdentifier(this.element, "chain--answer--component");
  }
}
__publicField(component_controller_default, "targets", ["openEndedSubmit"]);

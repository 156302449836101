export class LinkModule {
  constructor() {
  }
  addCurrentParamsToUrl(url) {
    const currentParams = window.location.search;
    return this.addParamsToUrl(url)(currentParams);
  }
  addParamsToUrl(url) {
    if (!url)
      return null;
    const paramsToRewriteDB = this.getParamsToRewriteFromTemplate();
    if (paramsToRewriteDB && Object.keys(paramsToRewriteDB).length > 0) {
      return (params) => {
        return this.getUrlWithRewritedParams({
          url,
          currentParams: params,
          paramsToRewrite: Object.keys(paramsToRewriteDB),
          sub1: paramsToRewriteDB["sub1"] ? this.rewriteParamWithThirdParty(paramsToRewriteDB["sub1"]) : void 0,
          sub3: paramsToRewriteDB["sub3"] ? this.rewriteParamWithThirdParty(paramsToRewriteDB["sub3"]) : void 0,
          sub4: paramsToRewriteDB["sub4"] ? this.rewriteParamWithThirdParty(paramsToRewriteDB["sub4"]) : void 0,
          sub5: paramsToRewriteDB["sub5"] ? this.rewriteParamWithThirdParty(paramsToRewriteDB["sub5"]) : void 0
        });
      };
    }
    return (params) => {
      const clickedLink = new URL(url);
      const clickedLinkParams = new URLSearchParams(clickedLink.search);
      const passedCurrentTabUrlParams = new URLSearchParams(params);
      const localParamsToDelete = {};
      passedCurrentTabUrlParams.forEach((_, key) => {
        if (clickedLinkParams.has(key) && passedCurrentTabUrlParams.get(key) != "") {
          localParamsToDelete[key] = passedCurrentTabUrlParams.get(key);
        } else if (clickedLinkParams.has(key) && passedCurrentTabUrlParams.get(key) == "") {
          localParamsToDelete[key] = clickedLinkParams.get(key);
        }
      });
      return this.getUrlWithRewritedParams({
        url,
        currentParams: params,
        paramsToRewrite: Object.keys(localParamsToDelete),
        sub1: localParamsToDelete["sub1"] || void 0,
        sub3: localParamsToDelete["sub3"] || void 0,
        sub4: localParamsToDelete["sub4"] || void 0,
        sub5: localParamsToDelete["sub5"] || void 0
      });
    };
  }
  getParamsToRewriteFromTemplate(selector = ".linkParamsRewriteRule") {
    console.log("@@@@@@@@getParamsToRewriteFromTemplate@@@@@@");
    try {
      const settingsEls = document.querySelectorAll(selector);
      if (settingsEls.length > 0) {
        let rules = {};
        Array.from(settingsEls).map((ruleEl) => {
          return ruleEl ? ruleEl.getAttribute("data-params-to-rewrite") : "";
        }).map((pairString) => {
          return pairString ? pairString.split("=") : [];
        }).map((pair) => {
          rules[pair[0]] = pair[1];
        });
        return rules;
      }
      return {};
    } catch (e) {
      console.log("get params from template error", e.message);
      return {};
    }
  }
  rewriteParamWithThirdParty(paramName) {
    const token = typeof LeadiD != "undefined" && LeadiD.token;
    let gclId;
    let gaClientId;
    if (window.GclModule) {
      gclId = window.GclModule.getGclid();
      gaClientId = window.GclModule.getGaClientId();
    } else {
      console.warn("GclModule not found (rewriteParamWithThirdParty)");
    }
    console.log("rewriteParamWithThirdParty(paramName)", paramName);
    switch (paramName) {
      case "token":
        return token;
      case "gclid":
        return gclId;
      case "gaClientId":
        return gaClientId;
      default:
        return paramName;
    }
  }
  getUrlWithRewritedParams({
    url,
    currentParams,
    paramsToRewrite,
    sub1,
    sub3,
    sub4,
    sub5
  }) {
    if (!url)
      return null;
    const origLink = new URL(url);
    const restParams = this.getClearParams(paramsToRewrite, currentParams, origLink);
    return origLink.origin + this.filterBadformattedParams(origLink.pathname) + "?" + this.generateParams(sub1, sub3, sub4, sub5, restParams);
  }
  filterBadformattedParams(pathname) {
    if (!pathname)
      return "";
    const path = pathname.split("&");
    return path && path.length > 0 ? path[0] : "";
  }
  getClearParams(paramsToDelete = [], initalParams, origLink) {
    const prevParams = origLink.searchParams;
    initalParams = ["&", "?"].includes(initalParams[0]) ? initalParams.substr(1) : initalParams;
    const urlParams = new URLSearchParams(initalParams);
    paramsToDelete.forEach((param) => {
      if (["sub1", "sub3", "sub4", "sub5"].includes(param)) {
        urlParams.delete(param);
      }
      prevParams.delete(param);
    });
    return urlParams.toString() + "&" + prevParams.toString();
  }
  generateParams(sub1, sub3, sub4, sub5, rest) {
    const sub1Pair = sub1 ? "sub1=" + sub1 + "&" : "";
    const sub3Pair = sub3 ? "sub3=" + sub3 + "&" : "";
    const sub4Pair = sub4 ? "sub4=" + sub4 + "&" : "";
    const sub5Pair = sub5 ? "sub5=" + sub5 + "&" : "";
    return sub1Pair + sub3Pair + sub4Pair + sub5Pair + rest;
  }
  isUrl(domain, path) {
    if (path && path.length > 0) {
      const locationPathname = window.location.pathname;
      if (locationPathname && locationPathname[locationPathname.length - 1] == "/") {
        return window.location.host == domain && window.location.pathname.slice(0, -1) == path;
      }
      return window.location.host == domain && window.location.pathname == path;
    }
    return window.location.host == domain;
  }
  popupByLink(url, newWindow) {
    let link = document.createElement("a");
    link.href = url;
    link.target = newWindow ? "_blank" : "_top";
    link.rel = "noreferrer noopener";
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
  performRedirect(url, newWindow = false) {
    if (window.self != window.top && !newWindow) {
      this.popupByLink(url, newWindow);
    } else {
      if (newWindow) {
        const btn = document.createElement("button");
        btn.onclick = function() {
          const redirectWindow = window.open(url, "_blank", "noreferrer");
          if (redirectWindow) {
            redirectWindow.opener = null;
            redirectWindow.location;
          }
        };
        btn.click();
      } else {
        this.popupByLink(url, newWindow);
      }
    }
    return;
  }
}

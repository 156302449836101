var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
export class EventTrackerModule {
  constructor(pixel) {
    __publicField(this, "pixel");
    this.pixel = pixel;
  }
  track(name, params) {
    console.log("EventTrackerModule::track pixel", this.pixel);
    console.log("EventTrackerModule::track name", name);
    console.log("EventTrackerModule::track params", params);
    const leadidToken = typeof LeadiD != "undefined" && LeadiD.token && typeof LeadiD.token != "undefined" && LeadiD.token;
    const currentParams = window.location.search;
    const additionalParams = {
      "userAgent": navigator.userAgent,
      "urlParams": currentParams,
      "leadid_token": leadidToken
    };
    this.pixel.track(name, { params, additionalParams });
  }
}

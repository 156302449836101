var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import ApplicationController from "@/controllers/application_controller";
import { EventTrackerModule } from "@/modules/event_tracker_module";
import { LinkModule } from "@/modules/link_module";
import { ResultsStoreModule } from "@/modules/results_store_module";
import ahoy from "ahoy.js";
export default class component_controller_default extends ApplicationController {
  constructor() {
    super(...arguments);
    __publicField(this, "trackerModule", new EventTrackerModule(ahoy));
    __publicField(this, "linkModule", new LinkModule());
  }
  initialize() {
    this.newTabRedirect;
    this.redirect;
    this.text;
    this.answerId;
  }
  connect() {
  }
  disconnect() {
  }
  handleAnswer() {
    return __async(this, null, function* () {
      yield this.trackAnswer();
      this.handleRedirect();
      if (!this.redirect)
        this.chainController.nextStep();
    });
  }
  getAnswerData(answer) {
    console.log("getAnswerData answer", answer);
    const answerData = answer.dataset;
    this.newTabRedirect = answerData == null ? void 0 : answerData.newTabRedirect;
    this.redirect = answerData == null ? void 0 : answerData.redirect;
    this.text = answerData == null ? void 0 : answerData.text;
    this.id = answerData == null ? void 0 : answerData.id;
  }
  handleRedirect() {
    if (this.newTabRedirect)
      this.linkModule.performRedirect(this.linkModule.addCurrentParamsToUrl(this.newTabRedirect), true);
    if (this.redirect)
      this.linkModule.performRedirect(this.linkModule.addCurrentParamsToUrl(this.redirect));
  }
  trackAnswer() {
    return __async(this, null, function* () {
      this.resultsStoreModule = yield ResultsStoreModule();
      yield this.resultsStoreModule.init();
      yield this.resultsStoreModule.saveAnswer(this.questionIdValue, this.text);
      console.log("THIS.SERVICENAME_VALUE::", this.questionServicenameValue);
      if (this.questionServicenameValue)
        yield this.resultsStoreModule.saveAnswer(this.questionServicenameValue, this.text);
      this.trackerModule.track("answer_click", {
        question_id: this.questionIdValue,
        question_chain_id: this.questionChainIdValue,
        answer_id: this.id,
        answer_text: this.text,
        page: window.location.pathname,
        url: window.location.href
      });
    });
  }
  get chainController() {
    return this.application.getControllerForElementAndIdentifier(document.querySelector(".chain"), "chain--component");
  }
}
__publicField(component_controller_default, "values", { questionId: String, questionChainId: String, questionServicename: String });
